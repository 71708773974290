import type { PropsWithChildren } from "react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "~/components/ui/tooltip";

type CustomTooltipProps = {
  tooltip: string;
};

const CustomTooltip = ({
  tooltip,
  children,
}: PropsWithChildren<CustomTooltipProps>): JSX.Element => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent className="normal-case">{tooltip}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default CustomTooltip;
